module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-E3896JR79C"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["fr","en","de","es","it","pt"],"defaultLanguage":"fr","siteUrl":"https://www.alpeaktaxi.com","i18nextOptions":{"fallbackLng":"fr","interpolation":{"escapeValue":false}},"localeJsonSourceName":"locales","pages":[{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/vehicule","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/reservation","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/chamonix","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/annecy","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/aeroport-geneve","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/gare","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/pro","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/station","getLanguageFromPath":true,"excludeLanguages":[]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
